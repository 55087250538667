.getStarted {
    padding: 5rem 1rem;
    text-align: center;
    background-color: #111152;
  }
  
  .getStartedTitle {
    font-size: 2.75rem;
    font-weight: 700;
    color: white;
    margin-bottom: 3rem;
  }
  
  .steps {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .step {
    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    transition: all 0.3s ease;
    flex: 1;
  }
  
  .step:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.1);
  }
  
  .step::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -1rem;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid rgba(255, 255, 255, 0.3);
    transform: translateY(-50%);
  }
  
  .step:last-child::before {
    display: none;
  }
  
  .step:first-child {
    color: #bc1dca;
    background: rgba(188, 29, 202, 0.1);
  }