.background {
    padding-top: 20px;
    background-color: #F0F0F5;
    width: 100%;
    height: auto;
    position: relative;
    text-align: center;
}

.list_wrap {
    display: inline-block;
    text-align: center;
    width: 100%;
}

.list {
    display: grid;
    grid-template-rows: repeat(auto-fill, 1fr);
    row-gap: 5px;
}

.ss_front{
    font-size: 25px;
    display: flex;
    justify-content: center;
}

.ss_middle{
    text-align: left;
    color: #28A149;
    font-size: 18px;
}

.ss_toggle{
    text-align: left;
    font-size: 18px;
}
.ss_back{
    padding-top: 5px;
    padding-bottom: 5px;
}
.ss_delete{
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: right;
}

.ss_delete_icon {
    border: #F6F5F5 solid 2px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ss_delete:hover{
    cursor: pointer;
}

.selected_stock{
    color: #F0F0F5;
    text-align: center;
    display: grid;
    padding: 5px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    background-color: #080823;
    border-radius: 10px;
    font-weight: 600;
    margin-top: 10px;
}

.port_draft_headers{
    width: 30vw;
    font-size: small;
    color: #1B263B;
    text-align: center;
    display: grid;
    grid-template-columns: 23% 23% 23% 23% 8%;
    padding: 5px 10px 5px 10px;
}

.stock_label {
    font-size: 15px;
    text-align: center;
    justify-content: center;
    font-weight: 700;
}

/* .selected_stock:hover{
    background-color: #949494;
    cursor: pointer;
    text-decoration: line-through;
    text-decoration-thickness: 3px;

} */


.percentage {
    display: border-box;
    text-align: center;
    border: 1px solid #F0F0F5;
    background-color: #1B263B;
    color: #F0F0F5;
    border-radius: 5px;
    font-size: 16px;
    width: 65px;
}

.percent_input{
    background-color: transparent;
    color: #F0F0F5;
    text-align: center;
    border: none;
    width: 40px;
    padding: 5px;

}
.percent_input:focus{
    outline: none;
}

.smallToggleButtonGroup {
    font-size: 0.75rem; /* Adjust the font size */
    padding: 2px; /* Adjust the padding */
}

@media (max-width: 800px) {
    .selected_stock {
        width: 95vw;
    }
    .port_draft_headers {
        width: 95vw;
    }
    .percent_input {
        width: 40px;
    }
    .percentage {
        width: 60px;
        margin-left: 7px;
    }
}