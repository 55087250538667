.header {
  background-color: #1B263B;
}
.component {
  display: grid;
  grid-template-columns: 10% 60% 10% 10% 10%;
  background-color: #1B263B;
  width: 100%;
  height: 80px;
}
.logo {
  max-height: 80px;
}
.sign_wrapper {
   /* Make the border wrap tightly around the text */
   padding-top: 10%;
}

.tab{
  color: white;
  text-align: center;
  padding-top: 25px;
  font-size: 26px;
  margin-bottom: -15px;
}
.tab:hover{
  background-color: #F0F0F5;
  color: #1B263B;
  cursor: pointer;
  
}


.sign_button {
  background-color: #1B263B; /* Green background */
  border: white 1px solid; /* Remove borders */
  color: white; /* White text */
  padding: 3px 8px; 
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  display: inline-block; 
  font-size: 20px; 
  cursor: pointer; 
  border-radius: 8px; 
  transition-duration: 0.4s;
  /* transform: translateY(-20px); */
}

.sign_button:hover {
  background-color: white; /* White background on hover */
  color: black; /* Black text on hover */
  border: 2px solid #4CAF50; /* Green border */
}

.responsiveButton {
  font-size: 20px; /* Default font size */
}

@media (max-width: 600px) {
  .responsiveButton {
      font-size: 10px; /* Smaller font size for small screens */
      line-height: 1.2;
  }
}