.section {
  background-color: #0A0B1C;
  padding: 4rem 1rem;
}

.container {
  max-width: 60rem;
  margin: 0 auto;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 3rem;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.feature {
  background-color: #1a1b2d;
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
}

.icon {
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
}

.featureTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
}

.featureDescription {
  color: #94a3b8;
  font-size: 1rem;
  line-height: 1.6;
}

/* Icon colors */
.iconSecure { color: #fbbf24; }
.iconRewards { color: #4ade80; }
.iconPurchasing { color: #60a5fa; }
.iconBeginner { color: #fde68a; }
.iconSimplicity { color: #c084fc; }
.iconTransparency { color: #22d3ee; }

@media (max-width: 768px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .title {
    font-size: 1.875rem;
    padding: 0 1rem;
  }
  
  .featureDescription {
    padding: 0 1rem;
  }
}

@media (max-width: 480px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }
}
