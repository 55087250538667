

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    width: 100vw;
    height: 100vh;
}


.modal {
    background: white;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: #1D1D6B;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    text-align: center;
}

.modal_close_button {
    text-align: center;
    border: #0059ff 2px solid;
    display: inline-block;
    padding: 2px 10px 2px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
}
.modal_close_button:hover {
    background-color: #1B263B;
    color: #F0F0F5;
}

.title_input {
    text-align: center;
    display: inline-block;
    background-color: #1D1D6B;
    border: 2px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 20px;
    color: #FFFF;
    width: 90%;
}
.title_input::placeholder {
    color: #FFFF;
    opacity: 0.5;
}
.title_input:focus::placeholder {
    color: transparent; /* Make the placeholder text disappear on focus */
}

.description_input {
    /* text-align: center; */
    display: inline-block;
    background-color: #1D1D6B;
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-size: 20px;
    color: #FFFF;
    resize: vertical;
    color: #FFFF;
    height: 180px;
    overflow: auto;/* Hide the scrollbar */
    resize: none; /* Make it non-expandable */
    width: 90%;
    margin-bottom: 10px;
}

.description_input::placeholder {
    color: #FFFF;
    opacity: 0.5;
    text-align: center;
}

.description_input::-webkit-scrollbar {
    display: none;
}
.description_input:focus::placeholder {
    color: transparent; /* Make the placeholder text disappear on focus */
}

.money_sign {
    position: absolute;
    font-size: 30px;
    font-weight: 700;
    transform: translateX(-5px) translateY(2px);
    color: #FFFF;
}

.investment_container {
    font-size: 20px;
    width: 90%;
    text-align: left;
    align-self: center;
    display: flex;
    justify-content: center;
    color: #FFFF;
    margin-bottom: 10px;
}

.investment_input {
    text-align: center;
    background-color: #1D1D6B;
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-size: 20px;
    width: 100%;
    color: #FFFF;
}

.investment_input::placeholder {
    color: #FFFF;
    opacity: 0.5;
}
.investment_input:focus::placeholder {
    color: transparent; /* Make the placeholder text disappear on focus */
}

.bar_container {
    width: 60%;
    height: 10;
    display: flex;
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: #FFFF;
    height: 14px;
    background-color: #1D1D6B;
}

.build_button {
    text-align: center;
    border: 2px solid #111152;
    display: inline-block;
    padding: 18px 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #FFFF;
}
.build_button:hover {
    background-color: #111152;
    color: #FFFF;
}
.error{
    color: red;
    font-size: 10px;
    margin: 10px;
}