.story {
  padding: 4rem 1rem;
  background-color: #111152;
  color: rgb(0, 0, 0);
}

.storyContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.storyTitle {
  font-size: clamp(2rem, 5vw, 3.75rem);
  font-weight: 700;
  text-align: center;
  margin-bottom: 2.5rem;
  color: white;
}

.storyContent {
  font-size: 1.125rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto 4rem;
  text-align: center;
  color: white;
}

.teamGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem;
  margin: 0 auto;
  max-width: 1200px;
}

.teamMember {
  background: #f8f9fa;
  border-radius: 16px;
  padding: 2rem;
  transition: transform 0.2s ease;
}

.teamMember:hover {
  transform: translateY(-5px);
}

.memberName {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.75rem;
}

.memberBio {
  font-size: 1rem;
  line-height: 1.6;
  text-align: center;
  color: #4a5568;
}

.memberImage {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 1rem;
  display: block;
  border: 3px solid #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .story {
    padding: 3rem 1rem;
  }
  
  .storyContainer {
    padding: 0 16px;
  }
  
  .teamGrid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .memberImage {
    width: 100px;
    height: 100px;
  }
}
